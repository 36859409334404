import toast from 'helpers/toast';
import moment from 'moment';
import Cookies from 'js-cookie'
import { customer, delivery, lockers } from './fakeData';

export const listRouterBack = {
  "/customer-lockers": "/customer-order",
  "/delivery-orders": "/delivery-login",
  "/delivery-return-counters": "/delivery-orders",
  "/delivery": "/delivery-order-phone",
  "/delivery-order-phone": "/delivery-orders",
}

export function checkExitsRoute (Routes) {
  let location_split = location.pathname.split("/");
  if (location_split.length == 4) {
    let _routes = Routes.filter((item) => item.path.split("/").length == 4);
    let result = false;
    for (let route of _routes) {
      let route_split = route.path.split("/")
      if (route_split[1] == location_split[1] && route_split[3] == location_split[3]) {
        result = true;
        break;
      }
    }
    return result;
  } else {
    let filter = Routes.filter((item) => item.path == location.pathname);
    return filter.length > 0;
  }
}

export function toastError (message_error) {
  if (message_error) {
    toast.error(message_error, "Error");
  } else {
    toast.error("Server Error", "Error");
  }
}

export function getCustomer () {
  return customer;
}

export function getDelivery () {
  return delivery;
}

export function randomDate (start) {
  let date = new Date();
  let end = new Date(date.setDate(date.getDate() - 3));
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

export function dateFormat (date) {
  return moment(date).format("YYYY-MM-DD HH:mm");
}

export function saveCookie(name, value, expires = null) {
  if (expires) {
    Cookies.set(name, value, {expires: expires});
  } else {
    Cookies.set(name, value)
  }
}

export function getCookie(name) {
  let value = Cookies.get(name);
  return value ? value : null;
}

export function removeCookie(name) {
  Cookies.remove(name);
}

export function getCurrentUser() {
  let user = getCookie('userDeliveryLastMileHub');
  user = user ? JSON.parse(user) : null;
  return user;
}

const pageResetOpenedToEmpty = ["/customer-order", "/delivery-orders", "/delivery-order-phone"];

export function getLockers (isReset = true) {
  let localStorageLockers = getCookie("lockersLastMileHub");
  localStorageLockers = localStorageLockers ? JSON.parse(localStorageLockers) : lockers;
  if (isReset && pageResetOpenedToEmpty.includes(location.pathname)) {
    localStorageLockers.map(item => { 
      if (item.status == "Opened") {
        item.status = "Empty";
      }
      return item;
    });
    saveCookie("lockersLastMileHub", JSON.stringify(localStorageLockers));
  }
  console.log(localStorageLockers);
  return localStorageLockers;
} 

export function saveLastTimeAction() {
  let dateTime = new Date().getTime();
  saveCookie("lastTimeAction", dateTime);
}

export function getLastTimeAction() {
  let lastTime = getCookie("lastTimeAction");
  return lastTime ? lastTime : null;
}

export function checkLastTimeAction(time) {
  let lastTime = getLastTimeAction();
  return !lastTime || lastTime == time;
}

export function hasBeenUpdate () {
  toast.warning("The lockers is changed.");
  setTimeout(() => {
    window.location.reload();
  }, 1500);
}
  
export function getTimeoutVariable () {
 return 20000;
}
  