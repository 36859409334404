import React, { Component } from "react"
import { removeCookie } from "helpers/supportFunction"

class ClearCache extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = () => {
    removeCookie("lockersLastMileHub");
    removeCookie("userDeliveryLastMileHub");
    removeCookie("userCustomerLastMileHub");
    setTimeout(() => {
      window.location = '/customer-order';
    }, 1500);
  }

  render() {
    return (
      <>
      </>
    )
  }
}

export default ClearCache
