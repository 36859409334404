import PropTypes from "prop-types"
import React, { Component } from "react"
import {
    Row,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Button
} from "reactstrap"
import { Link } from "react-router-dom"
import { getCurrentUser, listRouterBack, getLockers, removeCookie, saveCookie } from "helpers/supportFunction";
import user_customer from "assets/images/user_customer.svg"
import user_delivery from "assets/images/user_delivery.svg"
import toast from 'helpers/toast';

class Header extends Component {
  constructor(props) {
    super(props)
    this.refLinkGoBack = React.createRef();
    this.state = {
      btnSinOut: false,
      linkTo: "/customer-order",
    }
  }

  goBack = () => {
    this.checkUpdateStatusLockers();
    if (listRouterBack[location.pathname]) {
      this.setState({linkTo: listRouterBack[location.pathname]}, () => {
        this.refLinkGoBack.current.click();
      })
    }
  }

  checkUpdateStatusLockers = () => {
    let currentLockers = getLockers();
    if (currentLockers && currentLockers.length > 0) {
      if (location.pathname == '/delivery-return-counters') {
        currentLockers.map(item => {
          if (item.status == 'Opened') {
            item.status = 'Locked';
          }
          return item;
        });
        saveCookie("lockersLastMileHub", JSON.stringify(currentLockers));
      } else if (location.pathname == '/customer-lockers' || location.pathname == '/delivery') {
        currentLockers.map(item => {
          if (item.status == 'Opened') {
            item.status = 'Empty';
          }
          return item;
        });
        saveCookie("lockersLastMileHub", JSON.stringify(currentLockers));
      }
    }
  }
  
  singOut = (user) => {
    this.checkUpdateStatusLockers();
    removeCookie('userDeliveryLastMileHub');
    toast.success("Logout successfully");
    if (user.type == 'customer') {
      setTimeout(() => {
        window.location = "/customer-order";
      }, 1500);
    } else {
      setTimeout(() => {
        window.location = "/delivery-login";
      }, 1500);
    }
  }

  renderSwitchAccount () {
    if (location.pathname == '/customer-order' || location.pathname == '/delivery-login') {
      return <>
        <Link to={location.pathname != '/delivery-login' ? '/delivery-login' : '/customer-order'} className="tag-a-switch">
          <img src={location.pathname != '/delivery-login' ? user_customer : user_delivery} alt={`Switch account`} className="img-fluid" />
        </Link>
      </>
    } else if (location.pathname == '/customer-lockers') {
      return <a className="tag-a-switch"><img src={location.pathname != '/delivery-login' ? user_customer : user_delivery} alt={`Switch account`} className="img-fluid" /></a>;
    } else {
      return <Dropdown
        isOpen={this.state.btnSinOut}
        toggle={() => { let btnSinOut = !this.state.btnSinOut; this.setState({ btnSinOut: btnSinOut }) }}
      >
        <DropdownToggle >
          {
            getCurrentUser() && getCurrentUser().type == 'delivery' ?
              <img src={user_delivery} className="img-fluid" alt="Logout"/>
            : ''
          }
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Button
            onClick={() => this.singOut(getCurrentUser())}
            className="btn-info"
            color="info"
            alt="Logout"
          >
            <i className="fas fa-power-off font-size-16 align-middle me-2 text-danger" />
            <span>Logout</span>
          </Button>
        </DropdownMenu>
      </Dropdown>
    }
  }

  render() {
    return (
      <header id="page-topbar">
        <div className="height-100">
          <Link ref={this.refLinkGoBack} className="d-none" to={this.state.linkTo}></Link>
          <Row>
            <div className="" id="col-back">
              { this.props.isShowBack ?
                <i className="fa fa-chevron-left icon-back" onClick={() => { this.goBack()}}></i>
              : ''}
            </div>
            <div className="" id="col-logo-label">
              <label className="logo-label">LastMile Hub </label>
            </div>
            <div className="" id="col-user">
              { this.renderSwitchAccount() }
            </div>
          </Row>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  history: PropTypes.any,
  isShowBack: PropTypes.bool
}

export default Header;
