import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import Countdown from 'react-countdown';
import Locker from "components/customers/Locker"
import { getLockers, getCookie, saveCookie, getLastTimeAction, checkLastTimeAction, saveLastTimeAction, hasBeenUpdate, getTimeoutVariable } from "helpers/supportFunction"

class LockersPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentLocker: null,
      lockers: getLockers(),
      time_countdown: Date.now() + getTimeoutVariable(),
      lastTimeAction: getLastTimeAction()
    }
  }

  componentDidMount = () => {
    let location = this.props && this.props.location ? this.props.location : null;
    let customer = getCookie("userCustomerLastMileHub");
    if (customer && location && location.params && location.params.pincode && location.params.pincode.length > 0) {
      let currentLocker = this.state.lockers.find((item) => { return item.pincode == location.params.pincode[0]});
      currentLocker.isReset = false;
      this.setState({currentLocker: currentLocker, time_countdown: Date.now() + getTimeoutVariable()});
      this.timeoutUpdateStatusLocker();
    } else {
      this.props.history.push("/customer-order")
    }
  }

  timeoutUpdateStatusLocker() {
    setTimeout(() => {
      if (this.state.currentLocker && this.state.currentLocker.id && this.state.lastTimeAction) {
        let _lockers = getLockers();
        let currentLocker = _lockers.find((item) => { 
          return item.id == this.state.currentLocker.id && item.status == 'Opened' && !this.state.currentLocker.isReset
        });
        console.log(currentLocker);
        if (currentLocker) {
          currentLocker.status = 'Empty';
          this.onUpdateData(currentLocker, true);
          saveLastTimeAction();
        }
        if (location.pathname == '/customer-lockers') {
          if (this.props.history) {
            this.props.history.push({pathname: "/customer-order"})
          } else {
            window.location = '/customer-order';
          }
        }
      }
    }, getTimeoutVariable());
  }

  handleSubmit = () => {
    let currentLocker = this.state.currentLocker;
    if (checkLastTimeAction(this.state.lastTimeAction)) {
      currentLocker.status = 'Empty';
      this.onUpdateData(currentLocker, true);
      saveLastTimeAction();
      if (this.props.history) {
        this.props.history.push({pathname: "/customer-order"})
      } else {
        window.location = '/customer-order';
      }
    } else {
      currentLocker.status = 'Locked';
      this.onUpdateData(currentLocker, true);
      hasBeenUpdate();
    }
  }

  onUpdateData = (currentLocker, saveStore = false) => {
    let _lockers = getLockers();
    _lockers = _lockers.map((item) => {
      if (item.id == currentLocker.id) {
        item = currentLocker;
      }
      return item;
    })
    if (location.pathname == '/customer-lockers') {
      this.setState({ currentLocker: currentLocker, lockers: _lockers });
    }
    if (saveStore) {
      saveCookie('lockersLastMileHub', JSON.stringify(_lockers));
    }
  }

  renderer = ({ hours, minutes, seconds, completed }) => {
    return <span>{ String(minutes).padStart(2, '0')}:{ String(seconds).padStart(2, '0')}</span>;
  };

  onEventLongPress = () => {
    if (!this.state.currentLocker.isReset) {
      let currentLocker = this.state.currentLocker;
      currentLocker.isReset = true;
      currentLocker.status = "Locked";
      this.onUpdateData(currentLocker, true);
      if (checkLastTimeAction(this.state.lastTimeAction)) {
        saveLastTimeAction();
        setTimeout(() => {
          if (this.props.history) {
            this.props.history.push({pathname: "/customer-order"})
          } else {
            window.location = '/customer-order';
          }
        }, 500);
      }  else {
        hasBeenUpdate();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Customer Lockers | LastMile Hub</title>
        </MetaTags>
        <Container>
          <Row className="justify-content-center my-4">
            <div className="col-12 pt-4">
              <h1 className="text-center text-bold color-white pb-2">Please collect your order</h1>
              <h2 className="text-center text-bold color-yellow pb-1">1 locker open</h2>
              <h3 className="text-center color-white pb-1">The lockers will automatically lock in:</h3>
              <h1 className="text-center text-bold color-white pb-1"><Countdown date={this.state.time_countdown} renderer={this.renderer}/></h1>
              <h3 className="text-center color-white pb-4"> Pincode can be used repeatedly</h3>
              <div className="p-2 form-input-custom pt-4 mobile-margin">
                <div className="form-horizontal font-size-20">
                  <Row>
                    <div className="col-6-locker-left">
                      <div className="row locker-type">
                        <div className="col-6 dev-center">
                          <h4>CHILLED</h4>
                        </div>
                        <div className="col-6 dev-center">
                          <h4>FROZEN</h4>
                        </div>
                      </div>
                      <div className="row">
                        {
                          this.state.lockers.map((element, index) => {
                            if (index < 6) {
                              return <div className="col-6 dev-center mt-3 customer-lockers" key={`locker_no_1_` + index}>
                                <Locker 
                                  currentLocker={this.state.currentLocker}
                                  element={element}
                                  onLongPress={() => this.onEventLongPress()}
                                />
                              </div>
                            }
                          })
                        }
                      </div>
                    </div>
                    <div className="col-hr-locker">
                      <div className="hr-middle"></div>
                    </div>
                    <div className="col-6-locker-right">
                      <div className="row locker-type">
                        <div className="col-12 dev-center">
                          <h4>HEATED</h4>
                        </div>
                      </div>
                      <div className="row">
                        {
                          this.state.lockers.map((element, index) => {
                            if (index > 5) {
                              return <div className="col-6 dev-center mt-3 customer-lockers" key={`locker_no_2_` + index}>
                                <Locker 
                                  currentLocker={this.state.currentLocker}
                                  element={element}
                                  onLongPress={() => this.onEventLongPress()}
                                />
                              </div>
                            }
                          })
                        }
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <Row>
                <div className="col-12 button-position">
                  <div className="text-center">
                    <h3 className="pt-2">
                      Order number
                    </h3>
                    <h1 className="text-bold pb-3">
                      { this.state.currentLocker ? this.state.currentLocker.orderNumber : null }
                    </h1>
                  </div>
                  <div className="m-auto text-center">
                    <button
                      className="btn btn-success btn-submit-custom width-collected-btn"
                      type="submit"
                      onClick={() => this.handleSubmit()}
                    >
                      I have collected my order
                    </button>
                  </div>
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

LockersPage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.any
  }),
  location: PropTypes.any
}

export default LockersPage;
