import React from "react"
import PropTypes from "prop-types"

const LockerComponent = (props) => {
  const { lockerIdsOpen, element, onDBClick } = props;

  function getIcon () {
    if (lockerIdsOpen && lockerIdsOpen.includes(element.id)) {
      if (element.status == 'Opened') {
        return <i className="fas fa-lock-open-alt"></i>
      } else if (element.status == 'Locked') {
        return <i className="fas fa-lock"></i>
      }
      return '';
    }
  }

  function getBgClass () {
    if (lockerIdsOpen && lockerIdsOpen.includes(element.id)) {
      if (element.status == 'Opened' || element.status == 'Locked') {
        return 'status-' + element.status;
      }
    }
    return 'status-Empty';
  }

  function dbClick () {
    onDBClick();
  }

  return (
    <>
      <div
        className={
          `locker-square ${ getBgClass() }`
        }
        onDoubleClick={ dbClick }
      > 
        <div className="icon-status">
          { getIcon() }
        </div>
        <div className="locker-number">
          <span>{element.id}</span>
        </div>
      </div>
    </>
  )
}

LockerComponent.propTypes = {
  lockerIdsOpen: PropTypes.any,
  element: PropTypes.any,
  onDBClick: PropTypes.any
}

export default LockerComponent;
