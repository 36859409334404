import React, { Component } from "react"
import PropTypes from "prop-types"
import { keyboardKeys } from "../helpers/fakeData";

class InputComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listKey: keyboardKeys
    }
  }

  inputNo = (keyId) => {
    this.props.onInputKeyNo(keyId);
  }

  render() {
    return (
      <>
        <div className="text-center">
          <div className="row max-width" style={{maxWidth: '70%', margin: 'auto'}}>
            {
              keyboardKeys.map((element, index) => {
                return <div className="col-4 text-center mt-20" key={`key-no_` + index}>
                  <button
                    className={`btn btn-primary btn-keys btn-key-no`}
                    alt={element.id}
                    title={element.id}
                    type="button"
                    onClick={() => this.inputNo(element.id) }
                  >   
                    {
                      element.id == 'delete' ? <i className="fas fa-times"></i>
                      : ( element.id == 'back' ? 
                        <i className="fas fa-arrow-left"></i> : <span> {element.label}</span>
                      )
                    }
                  </button>
                </div>
              })
            }
          </div>
        </div>
      </>
    )
  }
}

InputComponent.propTypes = {
  onInputKeyNo: PropTypes.any
}

export default InputComponent;
