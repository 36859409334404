import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import LockerComponent from "components/deliveries/RetrieveLocker"
import { getLockers, saveCookie, saveLastTimeAction, getLastTimeAction, checkLastTimeAction, hasBeenUpdate } from "helpers/supportFunction"

class ReturnCounterPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lockerIdsOpen: [],
      lockers: getLockers(),
      lastTimeAction: getLastTimeAction()
    }
  }

  componentDidMount = () => {
    let location = this.props && this.props.location ? this.props.location : null;
    if (location && location.params && location.params.ids && location.params.ids.length > 0) {
      let lockerIdsOpen = this.state.lockers.filter((item) => { 
        if (item.status == 'Opened' && location.params.ids.includes(item.id)){ 
          return item
        }
      }).map(item => { return item.id });
      this.setState({lockerIdsOpen: lockerIdsOpen});
    } else {
      this.props.history.push("/delivery-orders")
    }
  }

  onUpdateData = (_lockers, saveStore = false) => {
    if (location.pathname == '/delivery-return-counters') {
      this.setState({ lockers: _lockers });
    }
    if (saveStore) {
      saveCookie('lockersLastMileHub', JSON.stringify(_lockers));
    }
  }

  handleSubmit = () => {
    let _lockers = getLockers();
    let _lockerIdsOpen = this.state.lockerIdsOpen;
    if (checkLastTimeAction(this.state.lastTimeAction)) {
      _lockers = _lockers.map((item) => {
        if (_lockerIdsOpen.includes(item.id) && item.status == "Opened") {
          item.status = "Empty";
        }
        return item;
      })
      this.onUpdateData(_lockers, true);
      saveLastTimeAction();
      if (this.props.history) {
        this.props.history.push({pathname: "/delivery-orders"})
      } else {
        window.location = '/delivery-orders';
      }
    } else {
      _lockers = _lockers.map((item) => {
        if (_lockerIdsOpen.includes(item.id) && item.status == "Opened") {
          item.status = "Locked";
        }
        return item;
      })
      this.onUpdateData(_lockers, true);
      hasBeenUpdate();
    }
  }

  renderer = ({ hours, minutes, seconds, completed }) => {
    return <span>{ String(minutes).padStart(2, '0')}:{ String(seconds).padStart(2, '0')}</span>;
  };

  onEventDBClick = (locker) => {
    if (this.state.lockerIdsOpen.includes(locker.id) && locker.status == 'Opened') {
      if (checkLastTimeAction(this.state.lastTimeAction)) {
        let _lockers = getLockers();
        _lockers = _lockers.map((item) => {
          if (item.id == locker.id) {
            item.status = "Locked";
          }
          return item;
        })
        this.onUpdateData(_lockers, true);
        saveLastTimeAction();
        this.setState({lastTimeAction: getLastTimeAction()});
        let lockerOpened = _lockers.filter(item => { return this.state.lockerIdsOpen.includes(item.id) && item.status == "Opened"});
        if (lockerOpened.length == 0) {
          setTimeout(() => {
            this.props.history.push({pathname: "/delivery-orders"})
          }, 500);
        }
      } else {
        hasBeenUpdate();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Delivery Return Counter | LastMile Hub</title>
        </MetaTags>
        <Container>
          <Row className="justify-content-center my-4">
            <div className="col-12 pt-4">
              <h1 className="text-center text-bold color-white pb-4 mb-4">{ this.state.lockerIdsOpen.length } locker is now open for retrieval</h1>
              <div className="p-2 form-input-custom pt-4 mobile-margin">
                <div className="form-horizontal font-size-20">
                  <Row>
                    <div className="col-6-locker-left">
                      <div className="row locker-type">
                        <div className="col-6 dev-center">
                          <h4>CHILLED</h4>
                        </div>
                        <div className="col-6 dev-center">
                          <h4>FROZEN</h4>
                        </div>
                      </div>
                      <div className="row">
                        {
                          this.state.lockers.map((element, index) => {
                            if (index < 6) {
                              return <div className="col-6 dev-center mt-3 cursor-return-counter" key={`locker_no_1_` + index}>
                                <LockerComponent 
                                  lockerIdsOpen={this.state.lockerIdsOpen}
                                  element={element}
                                  onDBClick={() => this.onEventDBClick(element)}
                                />
                              </div>
                            }
                          })
                        }
                      </div>
                    </div>
                    <div className="col-hr-locker">
                      <div className="hr-middle"></div>
                    </div>
                    <div className="col-6-locker-right">
                      <div className="row locker-type">
                        <div className="col-12 dev-center">
                          <h4>HEATED</h4>
                        </div>
                      </div>
                      <div className="row">
                        {
                          this.state.lockers.map((element, index) => {
                            if (index > 5) {
                              return <div className="col-6 dev-center mt-3 cursor-return-counter" key={`locker_no_2_` + index}>
                                <LockerComponent 
                                  lockerIdsOpen={this.state.lockerIdsOpen}
                                  element={element}
                                  onDBClick={() => this.onEventDBClick(element)}
                                />
                              </div>
                            }
                          })
                        }
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <Row>
                <div className="col-12 button-position">
                  <div className="m-auto text-center">
                    <button
                      className="btn btn-success btn-submit-custom"
                      type="submit"
                      onClick={() => this.handleSubmit()}
                    >
                      Retrieve done
                    </button>
                  </div>
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

ReturnCounterPage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.any
  }),
  location: PropTypes.any
}

export default ReturnCounterPage;
