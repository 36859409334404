import React from "react"
import PropTypes from "prop-types"
import { useLongPress } from "react-use";
import tapOrClick from 'react-tap-or-click'

const LockerComponent = (props) => {
  const { element, onDBClick, onClick, onLongPress } = props;

  const defaultOptions = {
    isPreventDefault: true,
    delay: 2000
  };
  const longPressEvent = useLongPress(onLongPress, defaultOptions);

  function getIcon () {
    if (element.status == 'Opened') {
      return <i className="fas fa-lock-open-alt"></i>
    } else if (element.status == 'Locked') {
      return <i className="fas fa-lock"></i>
    } else if (element.status == 'Abnormal') {
      return <i className="fas fa-exclamation-triangle"></i>
    }
    return '';
  }
  
  let lastClick = 0;
  let waitingClick = null;
  function click (event) {
    if (lastClick && event.timeStamp - lastClick < 250 && waitingClick) {
      lastClick = 0;
      clearTimeout(waitingClick);
      waitingClick = null;
      onDBClick()
      console.log("dbClick");
    } else {
      lastClick = event.timeStamp;
      waitingClick = setTimeout(() => {
        console.log("Click");
        onClick()
      }, 251);
    }
  }

  return (
    <div
    {...tapOrClick(click)}
    >
      <div
        className={
        `locker-square status-${ element.status }`
        }
        {...longPressEvent}
        id={"locker_event_" + element.id}
      >
        <div className="icon-status">
          { getIcon() }
        </div>
        <div className="locker-number">
          <span>
            {element.id}
          </span>
        </div>
      </div>
    </div>
  )
}

LockerComponent.propTypes = {
  element: PropTypes.any,
  onDBClick: PropTypes.any,
  onClick: PropTypes.any,
  onLongPress: PropTypes.any
}

export default LockerComponent
