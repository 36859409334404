import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import Header from "pages/Header"

const LayoutGlobal = props => {
  return <React.Fragment>
      <div id="body-pana-sl">
        <div className="card-overlay">
          <Header isShowBack={false}></Header>
          {props.children}
        </div>
      </div>
    </React.Fragment>
}

LayoutGlobal.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

export default withRouter(LayoutGlobal)
