// Authentication related pages
import CustomerOrderPage from "../pages/customers/OrderPage"
import CustomerLockersPage from "../pages/customers/LockersPage"
import DeliveryLogin from "../pages/AuthenticationInner/DeliveryLogin"
import DeliveryListOrderPage from "../pages/deliveries/DeliveryListOrderPage"
import DeliveryReturnCounterPage from "../pages/deliveries/ReturnCounterPage"
import DeliveryPage from "../pages/deliveries/DeliveryPage"
import DeliveryOrderPhonePage from "../pages/deliveries/OrderPhoneNumberPage"

import Pages404 from "../pages/PageNotFound"
import ClearCache from "../pages/CacheClear"

const Routes = [
  { path: "/customer-order", component: CustomerOrderPage, layout: false },
  { path: "/customer-lockers", component: CustomerLockersPage, layout: false },
  { path: "/delivery-login", component: DeliveryLogin, layout: false },
  { path: "/delivery-orders", component: DeliveryListOrderPage, layout: false, permission: 'delivery' },
  { path: "/delivery-return-counters", component: DeliveryReturnCounterPage, layout: false, permission: 'delivery' },
  { path: "/delivery", component: DeliveryPage, layout: false, permission: 'delivery' },
  { path: "/delivery-order-phone", component: DeliveryOrderPhonePage, layout: false, permission: 'delivery' },
  { path: "/page-404", component: Pages404, layout: false },
  { path: "/clear-cache", component: ClearCache, layout: false },
];

const RoutePermissionCustomer = ['/customer-lockers'];
const RoutePermissionDelivery = ['/delivery-orders', "/delivery-return-counters", '/delivery-order-phone', '/delivery'];

export { Routes, RoutePermissionCustomer, RoutePermissionDelivery }
