import React from "react"
import PropTypes from "prop-types"
import { useLongPress } from "react-use";

const LockerComponent = (props) => {
  const { currentLocker, element, onLongPress } = props;

  const defaultOptions = {
    isPreventDefault: true,
    delay: 2000
  };
  const longPressEvent = useLongPress(onLongPress, defaultOptions);

  function getIcon (element) {
    if (currentLocker && element.id == currentLocker.id) {
      if (currentLocker.isReset) {
        return '';
      } else {
        return  <i className="fas fa-lock-open-alt"></i>
      }
    }
  }
  return (
    <>
      <div
        className={
        `locker-square
            ${currentLocker && element.id == currentLocker.id && !currentLocker.isReset ? 'status-Opened' : 'status-Empty'
        }`}
        {...longPressEvent}
      > 
        <div className="icon-status">
        { getIcon(element) }
        </div>
        <div className="locker-number">
        <span>
          {element.id}
        </span>
        </div>
      </div>
    </>
  )
}

LockerComponent.propTypes = {
  currentLocker: PropTypes.any,
  element: PropTypes.any,
  onLongPress: PropTypes.any
}

export default LockerComponent;
