import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom";
import { Routes, RoutePermissionCustomer, RoutePermissionDelivery } from "routes"
import { checkExitsRoute, getCurrentUser, removeCookie } from 'helpers/supportFunction';

function checkPermission(permission) {
  if (permission == 'customer') {
    return RoutePermissionCustomer.includes(location.pathname);
  } else {
    return RoutePermissionDelivery.includes(location.pathname);
  }
}

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      const route = rest.route;
      if (!route || !checkExitsRoute(Routes) || (isAuthProtected && !getCurrentUser())) {
        removeCookie('userDeliveryLastMileHub');
        return ( <Redirect to={{ pathname: "/customer-order", state: { from: props.location } }}/> )
      } else if (getCurrentUser() && route.permission && !checkPermission(route.permission)) {
        let user = getCurrentUser();
        if (user.type == 'customer') {
          return ( <Redirect to={{ pathname: "/customer-order", state: { from: props.location } }}/> )
        } else {
          return ( <Redirect to={{ pathname: "/delivery-login", state: { from: props.location } }}/> )
        }
      }
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default Authmiddleware
