import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Container, Input } from "reactstrap"
import MetaTags from "react-meta-tags"
import { getLockers, saveCookie, saveLastTimeAction, getLastTimeAction, checkLastTimeAction, hasBeenUpdate } from "helpers/supportFunction"

class DeliveryListOrderPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listOpened: [],
      listLockers: getLockers(false),
      MasterChecked: false,
      isCheckLocker: false,
      SelectedList: [],
      lastTimeAction: getLastTimeAction()
    }
  }

  componentDidMount = () => {
    let listOpened = this.state.listLockers.filter(opened => {
      return opened.status === "Locked"
    }).map(item => {
      item.isChecked = false
      return item
    })

    this.setState({ listOpened: listOpened })
    if (listOpened.length < 1) {
      this.props.history.push("/delivery-order-phone")
    }
  }

  onMasterCheck(e) {
    let tempListLockers = this.state.listOpened
    tempListLockers.map(locker => (locker.isChecked = e.target.checked))
    this.setState({
      MasterChecked: e.target.checked,
      isCheckLocker: tempListLockers.filter(e => e.isChecked).length > 0,
      listOpened: tempListLockers,
      SelectedList: this.state.listOpened.filter(e => e.isChecked),
    })
  }

  onItemCheck(e, item) {
    let tempListLockers = this.state.listOpened
    tempListLockers.map(locker => {
      if (locker.id === item.id) {
        locker.isChecked = e.target.checked
      }
      return locker
    })
    const totalItems = this.state.listOpened.length
    const totalCheckedItems = tempListLockers.filter(e => e.isChecked).length

    this.setState({
      MasterChecked: totalItems === totalCheckedItems,
      isCheckLocker: totalCheckedItems > 0,
      listOpened: tempListLockers,
      SelectedList: this.state.listOpened.filter(e => e.isChecked),
    })
  }

  getSelectedRows() {
    this.setState({
      SelectedList: this.state.listOpened.filter(e => e.isChecked),
    })
  }

  clickSubmit = () => {
    if (checkLastTimeAction(this.state.lastTimeAction)) {
      if (!this.state.isCheckLocker) {
        this.props.history.push("/delivery-order-phone")
      } else {
        let ids = this.state.SelectedList.map(item => {
          return item.id
        })
        this.setState({
          listLockers: getLockers(false),
        }, () => {
          let _lockers = this.state.listLockers.map(item => {
              if (ids.includes(item.id) && item.status == "Locked") {
                item.status = "Opened"
              }
              return item
            })
            saveCookie("lockersLastMileHub", JSON.stringify(_lockers));
            saveLastTimeAction();
            this.props.history.push({pathname: "/delivery-return-counters", params: { ids: ids }});
        })
      }
    } else {
      hasBeenUpdate();
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Delivery Orders | LastMile Hub</title>
        </MetaTags>
        <div className="account-pages my-5">
          <Container>
            <Row className="justify-content-center">
              <div className="col-12 mb-150">
                <h1 className="text-center text-bold color-white pb-3">
                  {this.state.listOpened.length} uncollected order to be
                  retrieved
                </h1>
                <h3 className="text-center color-white pb-3">
                  Total of {this.state.listLockers.length} lockers
                </h3>
                <div className="p-2 form-input-custom">
                  <div className="form-horizontal font-size-20 delivery_orders_table">
                    <table className="table text-center">
                      <thead
                        style={{ backgroundColor: "#004EAE" }}
                        className="thead-dark text-white"
                      >
                        <tr>
                          <th scope="col">Order number</th>
                          <th scope="col">Number of lockers</th>
                          <th scope="col">Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.listOpened.map(locker => {
                          return (
                            <tr
                              key={locker.id}
                              className={locker.isChecked ? "selected" : ""}
                            >
                              <td className="text-left">
                                <label className="cursor-pointer mt-1 ml-16">
                                  <Input
                                    className="form-input mr-16"
                                    type="checkbox"
                                    checked={locker.isChecked}
                                    id={`row_check_${locker.id}`}
                                    onChange={e => this.onItemCheck(e, locker)}
                                  />
                                  <span className="text-bold font-size-18">
                                    {locker.orderNumber}
                                  </span>
                                </label>
                              </td>
                              <td>
                                <div className="number-of-locker">
                                  <span className="font-size-20">
                                    1
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div className="date-time mt-1">
                                  <span className="font-size-18">
                                    {locker.dateTime}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className="select-all">
                      <label className="cursor-pointer">
                        <Input
                          className="form-check-input d-none"
                          type="checkbox"
                          checked={this.state.MasterChecked}
                          id="mastercheck}"
                          onChange={e => this.onMasterCheck(e)}
                        />
                        <span>
                          {this.state.MasterChecked
                            ? "Deselect all"
                            : "Select all"}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <Row>
                <div className="col-12 button-position">
                  <div className="m-auto text-center">
                    {this.state.isCheckLocker ? (
                      <button
                        className="btn btn-success btn-submit-custom width-collected-btn"
                        type="submit"
                        onClick={() => this.clickSubmit()}
                      >
                        Open selected locker(s)
                      </button>
                    ) : (
                      <button
                        className="btn btn-success btn-submit-custom width-collected-btn"
                        type="submit"
                        onClick={() => this.clickSubmit()}
                      >
                        Do not collect any orders
                      </button>
                    )}
                  </div>
                </div>
              </Row>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

DeliveryListOrderPage.propTypes = {
  history: PropTypes.any,
}

export default DeliveryListOrderPage;
