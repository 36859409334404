import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Container,
  Input
} from "reactstrap"
import MetaTags from "react-meta-tags"
import InputComponent from "components/InputComponent"
import icon_password_pincode from "assets/images/icon_password_pincode.svg"
import icon_profile_ID from "assets/images/icon_profile_ID.svg"
import { delivery } from "../../helpers/fakeData"
import { getDelivery, saveCookie, removeCookie } from "helpers/supportFunction"
import toast from 'helpers/toast';

class DeliveryLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataForm: {
        id: "",
        pincode: "",
      },
      isSubmit: false,
      pinCodeSubmit: "",
      currentFocus: null,
      dataDelivery: delivery,
    }
  }

  componentDidMount = () => {
    removeCookie("userDeliveryLastMileHub")
  } 

  focusInput = (type) => {
    this.setState({
      currentFocus: type
    });
  }

  onInputKey = (key) => {
    if (this.state.currentFocus) {
      if (this.state.currentFocus == 'id' || this.state.currentFocus == 'pincode') {
        this.onActionInput(key, this.state.currentFocus);
      }
    }
  }

  onActionInput = (inputKey, type) => {
    let _dataForm = this.state.dataForm;
    let pinCodeSubmit = this.state.pinCodeSubmit;
    if (inputKey == 'delete') {
      _dataForm[type] = '';
      if (type == 'pincode') {
        pinCodeSubmit = _dataForm[type];
      }
    } else if (inputKey == 'back') {
      _dataForm[type] = _dataForm[type].slice(0, -1);
      if (type == 'pincode') {
        pinCodeSubmit = _dataForm[type];
      }
    } else {
      if (_dataForm[type].length < 8) {
        _dataForm[type] += inputKey;
      }
      if (type == 'pincode') {
        pinCodeSubmit += inputKey;
      }
    }
    if (type == "id" && _dataForm[type].length == 8) {
      this.setState({currentFocus: 'pincode'});
    }
    this.setState({ dataForm: _dataForm, pinCodeSubmit: pinCodeSubmit }, () => {
      if (this.state.dataForm.id.length == 8 && this.state.dataForm.pincode.length == 8 && this.state.pinCodeSubmit.length > 8) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit = () => {
    this.setState({ isSubmit: true });
    if (this.state.dataForm.id && this.state.dataForm.pincode) {
      if (this.state.dataForm.id == this.state.dataDelivery.username && this.state.dataForm.pincode == this.state.dataDelivery.pincode) {
        saveCookie("userDeliveryLastMileHub", JSON.stringify(getDelivery()), new Date(new Date().getTime() + 30 * 60000));
        this.props.history.push("/delivery-orders")
        toast.success("Login successfully");
      } else {
        toast.error("Id or pincode is incorrect. Try again.");
      }
    } else {
      toast.error("Please enter a valid id and pincode.");
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Delivery Login | LastMile Hub</title>
        </MetaTags>
        <div className="account-pages my-4">
          <Container>
            <Row className="justify-content-center">
              <div className="col-12">
                <h1 className="text-center text-bold color-white pb-2 font-24">Please enter your id and pincode</h1>
                <div className="p-2 form-input-custom">
                  <div
                    className="form-horizontal font-size-20"
                  >
                    <div className="mb-4">
                      <div className="input-container" onFocus={() => this.focusInput('id')}>
                        <img src={icon_profile_ID}/>
                        <Input
                          name="id"
                          className={`form-control
                            ${this.state.currentFocus && this.state.currentFocus == 'id' ? 'inputFocus' : '' }
                            ${this.state.dataForm.id ? 'letter-spacing' : '' }
                            `}
                          placeholder="ID"
                          type="text"
                          maxLength="8"
                          value={this.state.dataForm.id || ""}
                          autoComplete={0}
                          readOnly={true}
                          autoFocus={true}
                          invalid={ this.state.isSubmit && !this.state.dataForm.id ? true: false }
                        />
                      </div>
                    </div>
    
                    <div className="mb-32">
                      <div className="input-container" onFocus={() => this.focusInput('pincode')}>
                        <img src={icon_password_pincode}/>
                        <Input
                          name="pincode"
                          value={this.state.dataForm.pincode || ""}
                          className={`form-control
                            ${this.state.currentFocus && this.state.currentFocus == 'pincode' ? 'inputFocus' : '' }
                            ${this.state.dataForm.pincode ? 'letter-spacing' : '' }
                          `}
                          type="password"
                          maxLength="8"
                          placeholder="Pincode"
                          autoComplete={0}
                          readOnly={true}
                          invalid={ this.state.isSubmit && !this.state.dataForm.pincode ? true : false }
                        />
                      </div>
                    </div>

                    <InputComponent onInputKeyNo={this.onInputKey}/>

                    <Row>
                      <div className="col-12 button-position">
                        <div className="m-auto text-center">
                          <button
                            className="btn btn-success btn-submit-custom btn-width-submit w-50"
                            type="submit"
                            onClick={() => this.handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

DeliveryLogin.propTypes = {
  history: PropTypes.any
}

export default DeliveryLogin;
