import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import LockerComponent from "components/deliveries/DeliveryLocker"
import { getLockers, saveCookie, saveLastTimeAction, getLastTimeAction, checkLastTimeAction, hasBeenUpdate } from "helpers/supportFunction"
import moment from "moment"

class DeliveryPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orderNumber: null,
      lockers: getLockers(),
      onWaitEvent: false,
      lastTimeAction: getLastTimeAction()
    }
  }

  componentDidMount = () => {
    let location = this.props && this.props.location ? this.props.location : null;
    if (location && location.params && location.params.orderNumber) {
      this.setState({orderNumber: location.params.orderNumber});
    } else {
      this.props.history.push("/delivery-order-phone")
    }
    let _lockers = this.state.lockers;
    _lockers = _lockers.map((item) => {
      if (item.status == "Opened") {
        item.status = "Locked";
      }
      return item;
    })
    this.onUpdateData(_lockers, true);
  }

  onUpdateData = (_lockers, saveStore = false) => {
    if (location.pathname == '/delivery') {
      this.setState({ lockers: _lockers });
    }
    if (saveStore) {
      saveCookie('lockersLastMileHub', JSON.stringify(_lockers));
    }
  }

  handleSubmit = () => {
    let _lockers = getLockers();
    if (_lockers && _lockers.length > 0) {
      if (checkLastTimeAction(this.state.lastTimeAction)) {
        _lockers.map((item) => {
          if (item.status == "Opened") {
            item.status = "Locked";
            item.dateTime = moment().format("YYYY-MM-DD HH:mm");
            item.pincode = this.state.orderNumber;
            item.orderNumber = this.state.orderNumber;
          }
          return item;
        })
        this.onUpdateData(_lockers, true);
        if (location.pathname == '/delivery') {
          if (this.props.history) {
            this.props.history.push({pathname: "/delivery-order-phone"})
          } else {
            window.location = '/delivery-order-phone';
          }
        }
      } else {
        hasBeenUpdate();
      }
    }
  }

  onEventDBClick = (locker) => {
    if (locker.status == 'Opened') {
      if (checkLastTimeAction(this.state.lastTimeAction)) {
        let _lockers = this.state.lockers;
        _lockers.map((item) => {
          if (item.id == locker.id) {
            item.status = "Empty";
          }
          return item;
        })
        this.onUpdateData(_lockers, true);
        this.onWaitEventAction();
        saveLastTimeAction();
        this.setState({lastTimeAction: getLastTimeAction()});
      } else {
        hasBeenUpdate();
      }
    }
  }

  onEventClick = (locker) => {
    let countLockerOpen = this.state.lockers.filter(item => { return item.status == 'Opened'});
    if (locker.status == 'Empty' && countLockerOpen.length == 0) {
      if (checkLastTimeAction(this.state.lastTimeAction)) {
        let _lockers = this.state.lockers;
        _lockers.map((item) => {
          if (item.id == locker.id) {
            item.status = "Opened";
          }
          return item;
        })
        this.onUpdateData(_lockers, true);
        this.onWaitEventAction();
        saveLastTimeAction();
        this.setState({lastTimeAction: getLastTimeAction()});
      } else {
        hasBeenUpdate();
      }
    }
  }

  onEventLongPress = (locker) => {
    if (locker.status == 'Abnormal') {
      if (checkLastTimeAction(this.state.lastTimeAction)) {
        let _lockers = this.state.lockers;
        _lockers.map((item) => {
          if (item.id == locker.id) {
            item.status = "Empty";
          }
          return item;
        })
        this.onUpdateData(_lockers, true);
        this.onWaitEventAction();
        saveLastTimeAction();
        this.setState({lastTimeAction: getLastTimeAction()});
      } else {
        hasBeenUpdate();
      }
    }
  }

  onWaitEventAction = () => {
    this.setState({onWaitEvent: true});
    setTimeout(() => {
      this.setState({onWaitEvent: false});
    }, 500);
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Delivery | LastMile Hub</title>
        </MetaTags>
        <Container>
          <Row className="justify-content-center my-4">
            <div className="col-12 pt-4">
              <h1 className="text-center text-bold color-white pb-1">Select lockers for order</h1>
              <h1 className="text-center text-bold color-white pb-5 mobile-p-0">{ this.state.orderNumber }</h1>
              <div className="p-2 form-input-custom pt-4 mobile-margin">
                <div className="form-horizontal font-size-20">
                  <Row>
                    <div className="col-6-locker-left">
                      <div className="row locker-type">
                        <div className="col-6 dev-center">
                          <h4>CHILLED</h4>
                        </div>
                        <div className="col-6 dev-center">
                          <h4>FROZEN</h4>
                        </div>
                      </div>
                      <div className="row">
                        {
                          this.state.lockers.map((element, index) => {
                            if (index < 6) {
                              return <div className="col-6 dev-center mt-3 cursor-delivery" key={`locker_no_1_` + index}>
                                <LockerComponent 
                                  element={element}
                                  onDBClick={() => this.state.onWaitEvent ? void(0) : this.onEventDBClick(element)}
                                  onLongPress={() => this.state.onWaitEvent ? void(0) : this.onEventLongPress(element)}
                                  onClick={() => this.state.onWaitEvent ? void(0) : this.onEventClick(element)}
                                />
                              </div>
                            }
                          })
                        }
                      </div>
                    </div>
                    <div className="col-hr-locker">
                      <div className="hr-middle"></div>
                    </div>
                    <div className="col-6-locker-right">
                      <div className="row locker-type">
                        <div className="col-12 dev-center">
                          <h4>HEATED</h4>
                        </div>
                      </div>
                      <div className="row">
                        {
                          this.state.lockers.map((element, index) => {
                            if (index > 5) {
                              return <div className="col-6 dev-center mt-3 cursor-delivery" key={`locker_no_2_` + index}>
                                <LockerComponent 
                                  element={element}
                                  onDBClick={() => this.state.onWaitEvent ? void(0) : this.onEventDBClick(element)}
                                  onLongPress={() => this.state.onWaitEvent ? void(0) : this.onEventLongPress(element)}
                                  onClick={() => this.state.onWaitEvent ? void(0) : this.onEventClick(element)}
                                />
                              </div>
                            }
                          })
                        }
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <Row>
                <div className="col-12 button-position">
                  <h3 className="text-center text-black text-padding pb-desktop-160">Tap to add additional lockers and double tap to <br></br> remove locker(s).</h3>
                  <div className="m-auto text-center">
                    <button
                      className="btn btn-success btn-submit-custom btn-width-submit"
                      type="submit"
                      onClick={() => this.handleSubmit()}
                    >
                      Delivery done
                    </button>
                  </div>
                </div>
              </Row>
            </div>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

DeliveryPage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.shape({
    params: PropTypes.any
  }),
  location: PropTypes.any
}

export default DeliveryPage;
