import PropTypes from "prop-types"
import React from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

import { Routes } from "./routes"
import { checkExitsRoute } from "helpers/supportFunction"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import LayoutLogin from "./components/LayoutLogin"
import LayoutGlobal from "./components/LayoutGlobal"
import CustomerOrderPage from "pages/customers/OrderPage"

// Import scss
import 'bootstrap/dist/css/bootstrap.min.css';
import "toastr/build/toastr.min.css";
import "./assets/scss/theme.scss";

const App = props => {
  return (
    <React.Fragment>
      {
        checkExitsRoute(Routes) ?
          <Router>
            <Switch>
              {Routes.map((route, idx) => (
                <Authmiddleware
                  route={route}
                  path={route.path}
                  layout={route.path == "/customer-order" || route.path == "/delivery-login" ? LayoutLogin : LayoutGlobal}
                  component={route.component}
                  key={idx}
                  isAuthProtected={route.permission ? true : false}
                  exact
                />
              ))}
            </Switch>
          </Router>
        : 
          <>
            { 
              location.pathname != "/customer-order" ? 
                window.location = "/customer-order"
              : ''
            }
            <Authmiddleware
              layout={LayoutLogin}
              path={"/customer-order"}
              component={CustomerOrderPage}
              isAuthProtected={false}
              exact
            />
          </>
      }
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
