import React from "react"
import { Container, Row, Col } from "reactstrap"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <>
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <MetaTags>
            <title>Page Not Found | LastMile Hub</title>
          </MetaTags>
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 font-weight-medium">
                    404
                  </h1>
                  <h4 className="text-uppercase">Sorry, page not found</h4>
                </div>
              </Col>
            </Row>
            <Row className="text-center">
              <Link to={`/delivery-login`} className="mb-0">Back to login page</Link>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  )
}

export default Index
