import { randomDate, dateFormat } from "helpers/supportFunction";

export const keyboardKeys = [
    {
        id: "1",
        label: "1"
    },
    {
        id: "2",
        label: "2"
    },
    {
        id: "3",
        label: "3"
    },
    {
        id: "4",
        label: "4"
    },
    {
        id: "5",
        label: "5"
    },
    {
        id: "6",
        label: "6"
    },
    {
        id: "7",
        label: "7"
    },
    {
        id: "8",
        label: "8"
    },
    {
        id: "9",
        label: "9"
    },
    {
        id: "delete",
        label: "delete"
    },
    {
        id: "0",
        label: "0"
    },
    {
        id: "back",
        label: "back"
    }
];

export const customer = {
    id: 1,
    type: 'customer',
    username: 12345678,
    password: 12345678
}

export const delivery = {
    id: 1,
    type: 'delivery',
    username: 12345678,
    pincode: 12345678
}

export const lockers = [
    {
        id: 1,
        status: 'Abnormal', 
        pincode: "11111111",
        orderNumber: "11111111",
        type: "chilled",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 2,
        status: 'Locked',
        pincode: "22222222",
        orderNumber: "22222222",
        type: "frozen",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 3,
        status: 'Locked',
        pincode: "33333333",
        orderNumber: "33333333",
        type: "chilled",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 4,
        status: 'Locked',
        pincode: "44444444",
        orderNumber: "44444444",
        type: "frozen",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 5,
        status: 'Abnormal', 
        pincode: "55555555",
        orderNumber: "55555555",
        type: "chilled",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 6,
        status: 'Locked',
        pincode: "66666666",
        orderNumber: "66666666",
        type: "frozen",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 7,
        status: 'Empty',
        pincode: "77777777",
        orderNumber: "77777777",
        type: "heated",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 8,
        status: 'Empty',
        pincode: "88888888",
        orderNumber: "88888888",
        type: "heated",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 9,
        status: 'Empty',
        pincode: "99999999",
        orderNumber: "99999999",
        type: "heated",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 10,
        status: 'Empty',
        pincode: "10101010",
        orderNumber: "10101010",
        type: "heated",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 11,
        status: 'Empty',
        pincode: "11111110",
        orderNumber: "11111110",
        type: "heated",
        dateTime: dateFormat(randomDate(new Date()))
    },
    {
        id: 12,
        status: 'Abnormal',
        pincode: "12121212",
        orderNumber: "12121212",
        type: "heated",
        dateTime: dateFormat(randomDate(new Date()))
    }
];