import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

export default store
