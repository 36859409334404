import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Container,
  Input
} from "reactstrap"
import MetaTags from "react-meta-tags"
import InputComponent from "components/InputComponent"
import icon_password_pincode from "assets/images/icon_password_pincode.svg"
import { getLockers, getCustomer, saveCookie, removeCookie, saveLastTimeAction, getLastTimeAction, checkLastTimeAction, hasBeenUpdate } from "helpers/supportFunction"
import toast from 'helpers/toast';

class OrderPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataForm: {
        pincode: "",
      },
      isSubmit: false,
      pinCodeSubmit: "",
      lastTimeAction: getLastTimeAction()
    }
  }

  componentDidMount = () => {
    removeCookie("userCustomerLastMileHub")
  }

  onInputKey = (key) => {
    this.onActionInput(key, 'pincode');
  }

  onActionInput = (inputKey, type) => {
    let _dataForm = this.state.dataForm;
    let pinCodeSubmit = this.state.pinCodeSubmit;
    if (inputKey == 'delete') {
      _dataForm[type] = '';
      pinCodeSubmit = _dataForm[type];
      this.setState({dataForm: _dataForm});
    } else if (inputKey == 'back') {
      _dataForm[type] = _dataForm[type].slice(0, -1);
      pinCodeSubmit = _dataForm[type];
    } else {
      if (_dataForm[type].length < 8) {
        _dataForm[type] += inputKey;
      }
      pinCodeSubmit += inputKey;
    }
    this.setState({dataForm: _dataForm, pinCodeSubmit: pinCodeSubmit }, () => {
      if (this.state.dataForm.pincode && this.state.dataForm.pincode.length == 8 && this.state.pinCodeSubmit.length > 8) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit = () => {
    this.setState({ isSubmit: true });
    if (this.state.dataForm.pincode) {
      let lockers = getLockers(false);
      let locker = lockers.find((item) => { return item.pincode == this.state.dataForm.pincode});
      if (checkLastTimeAction(this.state.lastTimeAction)) {
        if (locker && locker.status == 'Locked') {
          lockers = lockers.map((item) => { 
            if (item.id == locker.id) {
              item.status = "Opened";
            }
            return item;
          });
          saveCookie("userCustomerLastMileHub", JSON.stringify(getCustomer()), new Date(new Date().getTime() + 2 * 60000));
          saveCookie('lockersLastMileHub', JSON.stringify(lockers));
          saveLastTimeAction();
          this.props.history.push({pathname: "/customer-lockers", params: {pincode: [locker.pincode]}});
        } else {
          toast.error("The pincode is incorrect. Try again.");
        }
      } else {
        hasBeenUpdate();
      }
    } else {
      toast.error("Please enter a valid pincode.");
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Customer Order | LastMile Hub</title>
        </MetaTags>
        <Container>
          <div className="account-pages my-4">
            <Row className="justify-content-center">
              <div className="col-12">
                <h1 className="text-center text-bold color-white pb-2 font-24">Please enter the pincode sent to you via SMS</h1>
                <div className="p-2 form-input-custom">
                  <div
                    className="form-horizontal font-size-20"
                  >
                    <div className="mb-4">
                      <div className="input-container">
                        <img src={icon_password_pincode}/>
                        <Input
                          name="pincode"
                          className={`form-control
                            ${this.state.dataForm.pincode ? 'letter-spacing' : '' }
                          `}
                          placeholder="Pincode"
                          type="text"
                          maxLength="8"
                          value={this.state.dataForm.pincode || ""}
                          autoComplete={0}
                          readOnly={true}
                          autoFocus={true}
                          invalid={ this.state.isSubmit && !this.state.dataForm.pincode ? true: false }
                        />
                      </div>
                    </div>

                    <InputComponent onInputKeyNo={this.onInputKey}/>
                   
                    <Row>
                      <div className="col-12 button-position">
                        <div className="m-auto text-center">
                          <button
                            className="btn btn-success btn-submit-custom btn-width-submit w-50"
                            type="submit"
                            onClick={() => this.handleSubmit()}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

OrderPage.propTypes = {
  history: PropTypes.any
}

export default OrderPage;
