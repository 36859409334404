import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import Header from "pages/Header"

const locationLayouts = ["/customer-lockers", "/delivery-return-counters", "/delivery", "/delivery-orders"]

const LayoutGlobal = props => {
  return <React.Fragment>
      <div id="body-pana-sl" className={locationLayouts.includes(location.pathname) ? 'responsive-height' : '' }>
        <div className={locationLayouts.includes(location.pathname) ? 'card-overlay responsive-height' : 'card-overlay' }>
          <Header isShowBack={true}></Header>
          {props.children}
        </div>
      </div>
    </React.Fragment>
}

LayoutGlobal.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

export default withRouter(LayoutGlobal)
