import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Container, Input } from "reactstrap"
import MetaTags from "react-meta-tags"
import InputComponent from "components/InputComponent"
import icon_deliveryMan_phoneNum from "assets/images/icon_deliveryMan_phoneNum.svg"
import icon_deliveryMan_orderNum from "assets/images/icon_deliveryMan_orderNum.svg"
import { getLockers, saveLastTimeAction, getLastTimeAction, checkLastTimeAction, hasBeenUpdate } from "helpers/supportFunction"
import toast from "helpers/toast"

class DeliveryLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dataForm: {
        orderNumber: "",
        phoneNumber: "",
      },
      lockers: [],
      isSubmit: false,
      currentFocus: null,
      lastTimeAction: getLastTimeAction()
    }
  }

  componentDidMount = () => {}

  focusInput = type => {
    this.setState({
      currentFocus: type,
    })
  }

  onInputKey = key => {
    if (this.state.currentFocus) {
      if (
        this.state.currentFocus == "orderNumber" ||
        this.state.currentFocus == "phoneNumber"
      ) {
        this.onActionInput(key, this.state.currentFocus)
      }
    }
  }

  onActionInput = (inputKey, type) => {
    let _dataForm = this.state.dataForm
    if (inputKey == "delete") {
      _dataForm[type] = ""
      this.setState({
        dataForm: _dataForm,
      })
    } else if (inputKey == "back") {
      _dataForm[type] = _dataForm[type].slice(0, -1)
    } else {
      if ((type == "orderNumber" && _dataForm[type].length < 8) || type == "phoneNumber") {
        _dataForm[type] += inputKey
      }
    }
    if (type == "orderNumber" && _dataForm[type].length == 8) {
      this.setState({currentFocus: 'phoneNumber'});
    }
    this.setState({
      dataForm: _dataForm,
    })
  }

  onCheckInvalidData = () => {
    if (this.state.dataForm.orderNumber.length != 8) {
      toast.error("Please enter valid order number.");
      return false
    }
    if (this.state.dataForm.phoneNumber.length < 8) {
      toast.error("Please enter valid phone number.");
      return false
    }
    return true;
  }

  handleSubmit = () => {
    this.setState({ isSubmit: true })
    if (this.state.dataForm.orderNumber && this.state.dataForm.phoneNumber) {
      if (this.onCheckInvalidData()) {
        if (checkLastTimeAction(this.state.lastTimeAction)) {
          this.setState({ lockers: getLockers(false)}, () => {
            let checkExitOrder = this.state.lockers.filter(item => { return (item.status == 'Locked' || item.status == 'Opened') && item.orderNumber == this.state.dataForm.orderNumber});
            if (checkExitOrder.length > 0) {
              toast.error("Order number already used for a locker.");
            } else {
              saveLastTimeAction();
              this.props.history.push({ pathname: "/delivery", params: { orderNumber: this.state.dataForm.orderNumber } });
            }
          })
        } else {
          hasBeenUpdate();
        }
      }
    } else {
      toast.error("Please enter a valid order number and phone number.");
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Delivery Order Number | LastMile Hub</title>
        </MetaTags>
        <Container>
          <div className="account-pages my-4">
            <Row className="justify-content-center">
              <div className="col-12">
                <h1 className="text-center text-bold color-white pb-2 font-24">
                  Type in order number & phone number to assign lockers for the order
                </h1>
                <div className="p-2 form-input-custom">
                  <div className="form-horizontal font-size-20">
                    <div className="mb-4">
                      <div
                        className="input-container"
                        onFocus={() => this.focusInput("orderNumber")}
                      >
                        <img src={icon_deliveryMan_orderNum} />
                        <Input
                          name="orderNumber"
                          className={`form-control
                            ${
                              this.state.currentFocus &&
                              this.state.currentFocus == "orderNumber"
                                ? "inputFocus"
                                : ""
                            }
                            ${this.state.dataForm.orderNumber ? "letter-spacing" : ""}
                            `}
                          placeholder="Order Number"
                          type="text"
                          maxLength="8"
                          minLength="8"
                          value={this.state.dataForm.orderNumber || ""}
                          autoComplete={0}
                          readOnly={true}
                          autoFocus={true}
                          invalid={
                            this.state.isSubmit && !this.state.dataForm.orderNumber
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>

                    <div className="mb-2">
                      <div
                        className="input-container"
                        onFocus={() => this.focusInput("phoneNumber")}
                      >
                        <img src={icon_deliveryMan_phoneNum} />
                        <Input
                          name="phoneNumber"
                          value={this.state.dataForm.phoneNumber || ""}
                          className={`form-control
                            ${
                              this.state.currentFocus &&
                              this.state.currentFocus == "phoneNumber"
                                ? "inputFocus"
                                : ""
                            }
                            ${
                              this.state.dataForm.phoneNumber
                                ? "letter-spacing"
                                : ""
                            }
                          `}
                          type="text"
                          minLength="8"
                          placeholder="Phone Number"
                          autoComplete={0}
                          readOnly={true}
                          invalid={
                            this.state.isSubmit && !this.state.dataForm.phoneNumber
                              ? true
                              : false
                          }
                        />
                      </div>
                    </div>

                    <InputComponent onInputKeyNo={this.onInputKey} />

                    <Row>
                      <div className="col-12 button-position">
                        <div className="m-auto text-center">
                          <button
                            className="btn btn-success btn-submit-custom btn-width-submit w-50"
                            type="submit"
                            onClick={() => this.handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

DeliveryLogin.propTypes = {
  history: PropTypes.any,
}

export default DeliveryLogin
